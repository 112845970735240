<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-layers2"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    :show-action-button="false"
    @hide="handleClose"
  >
    <form class="my-2 position-relative">
      <Stepper
        v-model="state.activeStep"
        :steps="steps"
        container-classes-action="justify-content-end"
        :prevent-forward="state.preventForward"
        :btn-next-finish-text="state.modal.submitBtnText"
        center-aligned
        @beforeStep="handleSlideDirection"
        @complete="handleSubmit"
      >

        <div class="">
          <FadeSlideAnimation
            :leave-first="true"
            :direction-forward="state.stepperDirectionForward"
          >
            <div v-if="state.activeStep === 0" class="row">

              <div class="form-group col-12">
                <label for="name" class="col-form-label">
                  Menu Name:
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Menu name"
                  v-model.trim="state.form.name.value"
                  autocomplete="off"
                  required
                >
                <FormErrorMsg :error="state.form.name.error" />
              </div>

              <div class="form-group col-6 col-lg-4">
                <label for="price" class="col-form-label">
                  Price:
                  <span class="text-danger">*</span>
                </label>
                <FormInputHelp>Whole menu price</FormInputHelp>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="price"
                  placeholder="Menu Price"
                  v-model.number="state.form.price.value"
                  autocomplete="off"
                  required
                >
                <FormErrorMsg :error="state.form.price.error" />
              </div>

              <div class="form-group col-6 col-lg-4">
                <label for="sort" class="col-form-label">
                  Sort:
                  <span class="text-danger">*</span>
                </label>
                <FormInputHelp>Works on descending order</FormInputHelp>
                <input
                  type="number"
                  class="form-control"
                  id="sort"
                  placeholder="Sort position (descending)"
                  v-model.number="state.form.sort.value"
                  autocomplete="off"
                  required
                >
                <FormErrorMsg :error="state.form.sort.error" />
              </div>

              <div class="form-group col-6 col-lg-4">
                <label for="sort" class="col-form-label">
                  Enabled:
                  <span class="text-danger">*</span>
                </label>
                <FormInputHelp>Disabling will hide the addon from users</FormInputHelp>
                <div>
                  <SwitchComp
                    :checked="state.form.status"
                    enable-text="Enabled"
                    disable-text="Disabled"
                    @change="evt=>state.form.status=evt"
                  />
                </div>
              </div>

              <div class="form-group col-12">
                <label for="sort" class="col-form-label">
                  Description:
                </label>
                <div>
                <textarea
                  class="form-control"
                  v-model.trim="state.form.description.value"
                />
                  <FormErrorMsg :error="state.form.description.error" />
                </div>
              </div>

            </div>

            <div v-else-if="state.activeStep === 1" class="row">

              <div class="col-12">
                <span class="col-form-label">Items:</span>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="px-1">Type</th>
                      <th class="px-1">Allowed</th>
                      <th class="px-1">Remove</th>
                    </tr>
                    </thead>
                    <StackedAnimation
                      tag="tbody"
                      direction-in="left"
                      direction-scale="25%"
                      duration="2"
                      stack-duration="0.5"
                    >
                      <tr
                        v-for="typeData in state.selectedItemTypes"
                        :key="typeData.type"
                      >
                        <td class="item-name px-1">{{ typeData.type }}</td>
                        <td class="item-name px-1">{{ typeData.allowed_qty }}</td>
                        <td class="px-1 text-center">
                          <i
                            class="icon-circle-with-cross item-delete-icon p-1"
                            @click="handleItemTypeRemove(typeData.type)"
                          />
                        </td>
                      </tr>
                      <tr v-if="!state.selectedItemTypes" key="empty-type">
                        <td colspan="3" class="text-center p-3 fs15 text-muted">Nothing added, Please add Qty</td>
                      </tr>
                    </StackedAnimation>
                  </table>
                </div>
              </div>

              <div
                class="form-group col-12 col-md-8"
              >
                <label class="col-form-label">
                  Item Type:
                  <span class="text-danger">*</span>
                </label>
                <FormInputHelp>Item type that will allow number of set menu items</FormInputHelp>
                <TreeSelect
                  id="type"
                  placeholder="Select Items"
                  v-model="state.form.itemTypeSelect.value"
                  :options="state.form.itemTypeSelect.options"
                  :multiple="true"
                  :searchable="true"
                  :clearable="true"
                  :disableBranchNodes="true"
                  :showCount="true"
                />
                <FormErrorMsg :error="state.form.itemTypeSelect.error" />
              </div>

              <div class="form-group col-12 col-md-4">
                <label for="allowed-item-qty" class="col-form-label">
                  Allowed Qty:
                  <span class="text-danger">*</span>
                </label>
                <FormInputHelp>Provided number of items can be selected</FormInputHelp>
                <input
                  type="number"
                  minlength="0"
                  class="form-control"
                  id="allowed-item-qty"
                  placeholder="Item Price"
                  v-model.number="state.form.itemTypeQty.value"
                  autocomplete="off"
                  required
                >
                <FormErrorMsg :error="state.form.itemTypeQty.error" />
              </div>

              <div class="form-group col-12">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="!state.form.itemTypeSelect.value.length"
                    @click="handleItemTypeAdd"
                  >
                    <i class="icon-playlist_add fs20" /> Add to List
                  </button>
                </div>
              </div>

            </div>

            <div v-else-if="state.activeStep === 2" class="row">

              <template v-if="state.selectedItemTypes.length">
                <!--selected items-->
                <div class="col-12">
                  <span class="col-form-label">Items:</span>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr>
                        <th class="px-1">Name</th>
                        <th class="px-1">Addons</th>
                        <th class="px-1">Adds</th>
                        <th class="px-1">Remove</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template
                        v-for="(items, itemsType) in getItemsByType"
                        :key="itemsType"
                      >
                        <tr>
                          <td colspan="4" class="text-center item-type-name">{{ itemsType }}</td>
                        </tr>
                        <tr
                          v-for="item in items"
                          :key="`${itemsType}${item.id}`"
                          class="xyz-nested"
                        >
                          <td class="item-name px-1">{{ item.name }}</td>
                          <td class="px-1">
                            <span
                              v-for="addon in item.addons"
                              :key="`${itemsType}${item.id}${addon.id}`"
                              class="badge badge-addon addon-name m-1"
                            >
                              {{ addon.name }} - {{ currency(addon.add_price) }}
                            </span>
                          </td>
                          <td class="item-name px-1">{{ currency(item.add_price) }}</td>
                          <td class="px-1 text-center">
                            <i
                              class="icon-circle-with-cross item-delete-icon p-1"
                              @click="handleItemRemove(item)"
                            />
                          </td>
                        </tr>
                      </template>
                      <tr v-if="!Object.keys(getItemsByType).length" key="empty-items">
                        <td colspan="4" class="text-center p-3 fs15 text-muted">Nothing added, Please add Items /
                          Addons
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="form-group col-12 col-md-8">
                  <label class="col-form-label">
                    Item:
                    <span class="text-danger">*</span>
                  </label>
                  <FormInputHelp>Items that will charge extra</FormInputHelp>
                  <TreeSelect
                    id="type"
                    placeholder="Select Items"
                    v-model="state.form.itemSelect.value"
                    :options="state.form.itemSelect.options"
                    :multiple="false"
                    :searchable="true"
                    :clearable="true"
                    :disableBranchNodes="true"
                    :showCount="true"
                  />
                  <FormErrorMsg :error="state.form.itemSelect.error" />
                </div>

                <div class="form-group col-12 col-md-4">
                  <label for="item-price" class="col-form-label">
                    Item Price:
                    <span class="text-danger">*</span>
                  </label>
                  <FormInputHelp>Charge provided amount extra when customer selects this item</FormInputHelp>
                  <input
                    type="number"
                    step="0.10"
                    class="form-control"
                    id="item-price"
                    placeholder="Item Price"
                    v-model.number="state.form.itemPrice.value"
                    autocomplete="off"
                    required
                  >
                  <FormErrorMsg :error="state.form.itemPrice.error" />
                </div>

                <!--selected addons-->
                <FadeSlideAnimation>
                  <div
                    v-if="state.selectedAddons.length"
                    class="col-12"
                  >
                    <span class="col-form-label">Addons:</span>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>
                          <th class="px-1">Name</th>
                          <th class="px-1">Adds</th>
                          <th class="px-1">Remove</th>
                        </tr>
                        </thead>
                        <StackedAnimation
                          tag="tbody"
                          direction-in="left"
                          direction-scale="25%"
                          duration="2"
                        >
                          <tr
                            v-for="adn in state.selectedAddons"
                            :key="adn.id"
                            class="xyz-nested"
                          >
                            <td class="item-name px-1">{{ adn.name }}</td>
                            <td class="item-name px-1">{{ currency(adn.price) }}</td>
                            <td class="px-1 text-center">
                              <i
                                class="icon-circle-with-cross item-delete-icon p-1"
                                @click="handleItemAddonRemove(adn.id)"
                              />
                            </td>
                          </tr>
                        </StackedAnimation>
                      </table>
                    </div>
                  </div>
                </FadeSlideAnimation>

                <div class="addons-section shadow ">
                  <div class="row">
                    <div class="form-group col-12 col-md-8">
                      <!--suppress XmlInvalidId -->
                      <label for="addonsSelect" class="col-form-label">
                        Item Addon:
                      </label>
                      <FormInputHelp>Select item that the menu offers</FormInputHelp>
                      <TreeSelect
                        id="addonsSelect"
                        placeholder="Select Items"
                        v-model="state.form.addonsSelect.value"
                        :options="state.form.addonsSelect.options"
                        :multiple="true"
                        :searchable="true"
                        :clearable="true"
                        noOptionsText="No Addon Available"
                      />
                      <FormErrorMsg :error="state.form.addonsSelect.error" />
                    </div>

                    <div class="form-group col-12 col-md-4">
                      <label for="addon-price" class="col-form-label">
                        Addon Price:
                        <span class="text-danger">*</span>
                      </label>
                      <FormInputHelp>Charge provided amount extra when customer selects this addon</FormInputHelp>
                      <input
                        type="number"
                        step="0.10"
                        class="form-control"
                        id="addon-price"
                        placeholder="Item Price"
                        v-model.number="state.form.addonPrice.value"
                        autocomplete="off"
                        required
                      >
                      <FormErrorMsg :error="state.form.addonPrice.error" />
                    </div>

                    <div class="form-group d-grid gap-2 mt-2">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        :disabled="!state.form.addonsSelect.value.length"
                        @click="handleItemAddonAdd"
                      >
                        <i class="icon-plus" /> Add Addon
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group d-grid gap-2 mt-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="!state.form.itemSelect.value"
                    @click="handleItemAdd"
                  >
                    <i class="icon-playlist_add fs20" /> Add to Items List
                  </button>
                </div>
              </template>

              <template v-else>
                <div class="lead text-center">Please select Qty first</div>
              </template>

            </div>
          </FadeSlideAnimation>
        </div>


      </Stepper>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { decimal, maxLength, minLength, minValue, numeric, required } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';
import { collect } from 'collect.js';
import { currency } from '@/Mixins/appHelper';
import Stepper from '@/components/Util/Stepper';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';

export default {
  name: 'SetMenuAction',
  components: {
    Stepper,
    ModalComp,
    FormErrorMsg,
    FormInputHelp,
    SwitchComp,
    TreeSelect,
    FadeSlideAnimation,
    StackedAnimation
  },
  emits: ['hide'],
  props: {
    setMenu: {
      type: [Object, Boolean],
      default: false
    },
    updating: Boolean,
    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    // model open control
    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    const store = useStore();
    const itemsState = store.state.menu.items;

    const state = reactive({

      stepperDirectionForward: true,

      modal: {
        show: false,
        title: 'Create New Set Menu',
        submitBtnText: 'Add Menu'
      },

      activeStep: 0,
      preventForward: false,

      form: {
        // menu
        name: {
          error: false,
          value: ''
        },
        price: {
          error: false,
          value: 0
        },
        sort: {
          error: false,
          value: 0
        },
        status: true,
        description: {
          error: false,
          value: ''
        },

        // items qty
        itemTypeSelect: {
          value: [],
          options: [],
          error: false
        },
        itemTypeQty: {
          error: false,
          value: 1
        },

        // items
        itemPrice: {
          error: false,
          value: 0
        },
        itemSelect: {
          value: null,
          options: [],
          error: false
        },

        // Addons
        addonPrice: {
          error: false,
          value: 0.01
        },
        addonsSelect: {
          value: [],
          options: [],
          error: false
        },

        submitting: false
      },

      selectedItemTypes: [],
      selectedItems: [],

      selectedAddons: [],
    });

    const steps = [
      {
        title: 'Menu Details',
      },
      {
        title: 'Item Qty',
      },
      {
        title: 'Item & Addons Price',
      }
    ];

    const handleSlideDirection = ({ isForward }) => {
      state.stepperDirectionForward = isForward;
    };

    // prepare options for items select
    watch(() => state.selectedItemTypes, (nv) => {
      // re-make items
      makeItemsSelectOptions();

      // re-disable already selected items
      disableSelectedItems();
    }, { deep: true });

    // prepare options for item types select

    state.form.itemTypeSelect.options = computed(() => getItemTypes(itemsState));

    const getItemTypes = (itemsState) => {

      const list = itemsState.data?.typesWithItem;

      if (!list) return [];

      return list
        .map(item => ({
          id: item,
          label: item
        }));
    }

    // prepare options for addons select based on selected item
    watch([() => state.form.itemSelect.value, () => state.selectedAddons], (nv) => {

      const selectedItemId = nv[0];

      // when no item selected
      if (!selectedItemId) return [];

      // if items state not available
      const itemList = itemsState.data?.items;
      if (!itemList) return [];

      // get selected item from items state
      const selectedItem = itemList.find((itm) => itm.id === selectedItemId);
      if (!selectedItem) return [];

      state.form.addonsSelect.options = selectedItem.addons.map(addon => ({
        id: addon.id,
        label: addon.name
      }));

    });

    // reset selected addons when items changes
    watch(() => state.form.itemSelect.value, (nv) => {
      state.form.addonsSelect.value = [];
      state.selectedAddons = [];

      // clear item addons when no item selected
      if (!nv) state.form.addonsSelect.options = [];
    });

    // customize modal for update &
    // reset form when closed
    watch(() => props.updating, (nv) => {

      // reset form
      resetForm();

      // reset the form if updating
      if (!nv) {

        state.modal = {
          ...state.modal,
          title: 'Create New Set Menu',
          submitBtnText: 'Add Menu'
        };

        return;
      }

      // set stepper to 1st step
      state.activeStep = 0;

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Set Menu',
        submitBtnText: 'Save Changes'
      };

      // populate values
      state.form.name.value = props.setMenu.name;
      state.form.description.value = props.setMenu.description;
      state.form.price.value = props.setMenu.price;
      state.form.sort.value = props.setMenu.sort;
      state.form.status = props.setMenu.status;

      // populate selected types
      state.selectedItemTypes = props.setMenu.types.map(({ allowed_qty, type }) => ({
        allowed_qty,
        type
      }));

      // disable selected item types
      disableSelectedTypes();

      // populate selected items
      state.selectedItems = props.setMenu.items;

      // disable selected items
      disableSelectedItems();

    });

    // validation start
    const validationRules = {
      form: {
        name: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(80),
          }
        },
        description: {
          value: {
            maxLength: maxLength(350),
          }
        },
        price: {
          value: {
            required,
            decimal,
            minValue: minValue(0)
          }
        },
        itemPrice: {
          value: {
            required,
            decimal,
            minValue: minValue(0)
          }
        },
        itemSelect: {
          value: {
            minLength: minLength(1)
          }
        },
        itemTypeSelect: {
          value: {
            minLength: minLength(1)
          }
        },
        itemTypeQty: {
          value: {
            required,
            numeric,
            minValue: minValue(1)
          }
        },

        addonPrice: {
          value: {
            required,
            decimal,
            minValue: minValue(0)
          }
        },
        sort: {
          value: {
            required,
            minValue: minValue(0)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      // map errors
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.name.error = errors['form.name.value'] || false;
          state.form.description.error = errors['form.description.value'] || false;
          state.form.price.error = errors['form.price.value'] || false;
          state.form.itemPrice.error = errors['form.itemPrice.value'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
          state.form.itemSelect.error = errors['form.itemSelect.value'] || false;
          state.form.itemTypeSelect.error = errors['form.itemTypeSelect.value'] || false;
          state.form.itemTypeQty.error = errors['form.itemTypeQty.value'] || false;
          state.form.addonPrice.error = errors['form.addonPrice.value'] || false;
        },
        {
          'form.name.value': 'Name',
          'form.description.value': 'Description',
          'form.price.value': 'Menu Price',
          'form.itemPrice.value': 'Item Price',
          'form.itemSelect.value': 'Item',
          'form.itemTypeSelect.value': 'Item Type',
          'form.itemTypeQty.value': 'Item Type Qty',
          'form.addonPrice.value': 'Addon Price',
          'form.sort.value': 'Sort',
        }
      );

      // prevent forward in stepper
      state.preventForward = !ErrorHelper.checkCurrentValidity(validator);
    });
    // validation end

    const resetForm = () => {
      state.form.name.value = '';
      state.form.description.value = '';
      state.form.sort.value = 0;
      state.form.price.value = 0;
      state.form.itemPrice.value = 0;
      state.form.itemTypeQty.value = 1;
      state.form.status = true;
      state.form.itemTypeSelect.value = [];
      state.form.itemSelect.value = null;
      state.form.addonsSelect.value = [];

      // set all items enabled
      const lastSelectedItemIds = state.selectedItems.map(itm => itm.id);
      toggleSelectNestedOptions(lastSelectedItemIds, state.form.itemSelect.options, false);

      // set all item types enabled
      const lastSelectedItemTypes = state.selectedItemTypes.map(itm => itm.type);
      toggleSelectOptions(lastSelectedItemTypes, state.form.itemTypeSelect.options, false);

      // reset selected items
      state.selectedItems = [];
      state.selectedItemTypes = [];

      // set stepper to 1st step
      state.activeStep = 0;
    };

    const getItemsByType = computed(() => {
      return collect(state.selectedItems).groupBy('type').all();
    });

    const handleItemAdd = () => {
      const list = itemsState.data?.items;
      if (!list) return;

      const selectedItemId = state.form.itemSelect.value;
      const selectedAddonIds = state.selectedAddons.map(adn => adn.id);

      // find the item
      let selectedItem = list.find(itm => itm.id === selectedItemId);
      if (!selectedItem) return;

      selectedItem = {
        ...selectedItem,
        add_price: state.form.itemPrice.value
      };

      // find and attach addons
      if (selectedAddonIds.length) {
        selectedItem.addons = selectedItem.addons
          .filter(addon => selectedAddonIds.includes(addon.id)) // keep only selected addons
          .map(addon => { // set selected price
            const selectedAddonWithPrice = state.selectedAddons.find(adn => adn.id === addon.id);
            addon.add_price = selectedAddonWithPrice.add_price;
            return addon;
          })
        ;
      } else {
        selectedItem.addons = [];
      }

      state.selectedItems.push(selectedItem);

      // clear selected
      state.form.itemSelect.value = null;
      state.form.itemPrice.value = 0;

      // clear item addons
      state.form.addonsSelect.value = [];

      // disable the item so it can't be added again
      toggleSelectNestedOptions(selectedItem.id, state.form.itemSelect.options);
    };

    const handleItemTypeAdd = () => {

      const itemTypes = state.form.itemTypeSelect.value;
      const allowed_qty = state.form.itemTypeQty.value;

      itemTypes.map(type => {
        state.selectedItemTypes.push({
          type,
          allowed_qty
        });
      });

      // clear selected
      state.form.itemTypeSelect.value = [];
      state.form.itemTypeQty.value = 1;

      // disable the item so it can't be added again
      toggleSelectOptions(itemTypes, state.form.itemTypeSelect.options);
    };

    const handleItemAddonAdd = () => {
      const allItems = itemsState.data?.items;
      if (!allItems) return;

      const addonIds = state.form.addonsSelect.value;
      const itemId = state.form.itemSelect.value;
      const addonPrice = state.form.addonPrice.value;

      const addonItem = allItems.find(itm => itm.id === itemId);
      const addons = addonItem.addons.filter(adn => addonIds.includes(adn.id));

      addons.map(adn => {
        state.selectedAddons.push({
          id: adn.id,
          name: adn.name,
          add_price: addonPrice
        });
      });

      // disable the item so it can't be added again
      toggleSelectOptions(addonIds, state.form.addonsSelect.options);

      // clear selected
      state.form.addonsSelect.value = [];
      state.form.addonPrice.value = 0.01;
    };

    function toggleSelectOptions (itemIds, selectOptions, setDisabled = null) {
      selectOptions.map(option => {

        const ids = (typeof itemIds === 'number') ? [itemIds] : itemIds;
        if (ids.includes(option.id)) {

          // perform wanted action or just toggle
          option.isDisabled = (setDisabled === null)
            ? !option.isDisabled
            : setDisabled
          ;
        }
      });
    }

    /**
     * Changes select options disable state,
     * @param itemIds : Number|Array - select options id/ids
     * @param selectOptions : Array - the options that is using by the select
     * @param setDisabled : Boolean|Null - if true/false options will be enabled/disabled, null will toggle them
     */
    function toggleSelectNestedOptions (itemIds, selectOptions, setDisabled = null) {
      selectOptions.map(({ children }) => {
        children.map(option => {

          const ids = (typeof itemIds === 'number') ? [itemIds] : itemIds;
          if (ids.includes(option.id)) {

            // perform wanted action or just toggle
            option.isDisabled = (setDisabled === null)
              ? !option.isDisabled
              : setDisabled
            ;
          }
        });
      });
    }

    function makeItemsSelectOptions () {

      const list = itemsState.data?.items;
      if (!list) return [];

      const options = [];

      collect(list).groupBy('type').map((items, itmType) => {

        // only include items that's type selected
        const selectedItemTypes = state.selectedItemTypes.map(t => t.type);
        if (!selectedItemTypes.includes(itmType)) return;

        const option = {
          id: itmType,
          label: itmType,
          children: []
        };

        option.children = items.map(item => ({
          id: item.id,
          label: item.name,
          isDisabled: false
        })).all();

        options.push(option);
      });

      state.form.itemSelect.options = options;
    }

    function disableSelectedItems () {
      const menuItems = props.setMenu.items;
      if (!menuItems) return;

      const selectedItemIds = menuItems.map(itm => itm.id);
      toggleSelectNestedOptions(selectedItemIds, state.form.itemSelect.options, true);
    }

    function disableSelectedTypes () {
      const menuTypes = props.setMenu.types;
      if (!menuTypes) return;

      const selectedItemTypes = menuTypes.map(typ => typ.type);
      toggleSelectOptions(selectedItemTypes, state.form.itemTypeSelect.options, true);
    }

    const handleItemRemove = (item) => {
      state.selectedItems = state.selectedItems.filter(itm => itm.id !== item.id);

      // enable option
      toggleSelectNestedOptions(item.id, state.form.itemSelect.options);
    };

    const handleItemTypeRemove = (itemType) => {
      state.selectedItemTypes = state.selectedItemTypes.filter(itm => itm.type !== itemType);

      // enable option
      toggleSelectOptions(itemType, state.form.itemTypeSelect.options);
    };

    const handleItemAddonRemove = (addonId) => {
      state.selectedAddons = state.selectedAddons.filter(adn => adn.id !== addonId);

      // enable option
      toggleSelectOptions(addonId, state.form.addonsSelect.options);
    };

    function handleClose () {
      emit('hide', false);
    }

    function getSelectedItemsForSubmit () {
      return state.selectedItems.map(item => {
        return {
          id: item.id,
          add_price: item.add_price,
          addons: item.addons.map(addon => ({
            id: addon.id,
            add_price: addon.add_price
          }))
        };
      });

    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      const data = {
        name: state.form.name.value,
        description: state.form.description.value,
        price: parseFloat(state.form.price.value).toFixed(2),
        sort: state.form.sort.value,
        status: state.form.status,
        types: state.selectedItemTypes,
        items: getSelectedItemsForSubmit()
      };

      state.form.submitting = true;

      try {

        if (props.updating) {
          await store.dispatch('menu/updateSetMenu', { id: props.setMenu.id, data });
        } else {
          await store.dispatch('menu/createSetMenu', data);
        }

        // close the modal
        handleClose();

        resetForm();

        Toaster.successAlt({
          title: `Set Menu ${props.updating ? 'Updated' : 'Created'}`,
          message: `'${data.name}' has been ${props.updating ? 'updated' : 'created'}`
        });

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.name.error = ex(err.name);
          state.form.description.error = ex(err.description);
          state.form.price.error = ex(err.price);
          state.form.itemPrice.error = ex(err.add_price);
          state.form.sort.error = ex(err.sort);
          state.form.itemSelect.error = ex(err.items);
          state.form.addonsSelect.error = ex(err.addons);
        });

      }

      state.form.submitting = false;
    }

    return {
      getItemTypes,
      state,
      steps,
      getItemsByType,
      currency,
      handleItemTypeAdd,
      handleItemTypeRemove,
      handleItemAdd,
      handleItemRemove,
      handleItemAddonAdd,
      handleItemAddonRemove,
      handleClose,
      handleSubmit,
      handleSlideDirection
    };
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/sass/variables";

.item-name {
  font-size: 15px;
}

.item-delete-icon {
  font-size: 26px;
  color: $secondary;
}

.item-type-name {
  background-color: #f2f2f2;
  text-transform: capitalize;
  color: $black;
  font-size: 14px;
}

.addon-name {
  font-size: 12px;
}

.badge-addon {
  background: #eaedf8;
  color: #3b3b3b;
}

.table {
  border: 1px solid #e0e3ec;

  thead {
    background-color: #f3f3f3;

    th {
      padding: 0.4rem 1rem;
    }
  }

  td {
    padding: 3px;
  }
}

.addons-section {
  border: 1px solid $border-color1;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
