<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Set Menu</div>
          <button
            v-if="isAuthorized(permissions.menu_set_menu_create)"
            class="btn btn-sm btn-primary p-2"
            @click="handleActionToggle"
          >
            <i class="icon-add"></i>Add New
          </button>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.menus"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissionsGroup.setMenu)"
          >

            <template #status="{item}">
              <span
                v-html="renderStatus(item.status)"
              ></span>
            </template>

            <template
              #action="{item}"
              v-if="isAuthorized(permissionsGroup.setMenu)"
            >
              <EditButton
                v-if="isAuthorized(permissions.menu_set_menu_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.menu_set_menu_delete)"
                :loading="item.id === state.action.subject?.id && state.action.deleteLoading"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_DELETE)"
              />
            </template>

          </ItemTable>

          <!--Add Modal-->
          <SetMenuAction
            :show="state.showAction"
            :set-menu="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import { constants } from '@/utils';
import Toaster from '@/utils/Toaster';
import SetMenuAction from '@/components/Menu/SetMenu/SetMenuAction';
import { fetchDataIfNotExist, isAuthorized, renderEnabledBadge } from '@/utils/Helper';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'SetMenu',
  components: { DeleteButton, EditButton, SetMenuAction, ItemTable, ButtonComp },
  setup () {

    const store = useStore();
    const setMenuStore = store.state.menu.setMenu;
    const itemsStore = store.state.menu.items;

    const state = reactive({
      showAction: false,

      loading: false,

      menus: [],
      columnMap: [
        { field: 'name', header: 'Name' },
        { field: 'price', header: 'Price', sortable: true },
        { field: 'sort', header: 'Sort', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
      ],

      action: {
        types: {
          status: false,
          edit: false,
          delete: false
        },
        subject: false,
        deleteLoading: false
      },
    });

    watch(() => setMenuStore.data, (nv) => {
      state.menus = nv || [];
    });

    onMounted(() => {
      fetchDataIfNotExist(setMenuStore.data, async () => {
        state.loading = true;

        await store.dispatch('menu/fetchSetMenus');
        await store.dispatch('menu/fetchItems');

        state.loading = false;
      }, () => {
        state.menus = setMenuStore.data;
      });
    });

    const renderStatus = (status) => {
      return renderEnabledBadge(status);
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;
      }

    };

    const handleDelete = (item) => {

      SweetAlert.confirmError({
        text: `This will permanently Delete '${item.name}'`,
        preConfirm: async (status) => {

        if (!status) return;

        state.action.deleteLoading = true;

        try {

          await store.dispatch('menu/deleteSetMenu', item.id);

          Toaster.successAlt({
            title: 'Deleted',
            message: `'${item.name}' has been deleted`
          })

        } catch (e) {
          Toaster.error({
            title: 'Deletion Failed',
            message: e.message
          });
        }

        state.action.deleteLoading = false;

      }
      });

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          state.action.types.edit = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_UPDATE_STATUS:
          state.action.types.delete = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          state.action.types.delete = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      constants,
      handleAction,
      renderStatus,
      handleActionToggle,
      isAuthorized,
      permissions,
      permissionsGroup
    };
  }
};
</script>
